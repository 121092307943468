.wpb_single_image {
  display: flex;
  flex-flow: column nowrap;

  .wpb_heading {
    display: flex;
    flex-flow: row wrap;
    align-content: center;
    justify-content: center;
    order: 2;
    height: 2.5em;
    margin-top: 1em !important;
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
    color: var(--color__blue);
    text-transform: capitalize;
    font: 400 1rem/1.2 Arial, sans-serif !important;

    > span {
      display: block;
      width: 100%;
      font-size: .8em;
      font-weight: 400;
    }
  }
}
