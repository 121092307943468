:root {
  --red: #de1c24;
  --blue: #065094;
  --yellow: #eff78f;
  --gray_l: #e5e5e5;
  --gray: #808080;
  --black: #333;

  --zIndex__top: 9999;
  --zIndex__middle: 999;
  --zIndex__bottom: 99;


  --a-bd_w: .8em;
  --adorn_w: 6px;
  --adorn_h: 4em;

  --pro_h: 23em;
}
@svg-load arrow url(../images/arrow-right.svg) {
  fill: var(--red);
}
@svg-load arrow_w url(../images/arrow-right.svg) {
  fill: white;
}

@define-mixin maxW {
  max-width: 60em;
  margin: 0 auto !important;
}
@define-mixin projectsImage {
  flex: 1;
  height: 100%;
  padding: .6em;

  .wpb_wrapper,
  .vc_single_image-wrapper,
  .vc_single_image-img {
    width: 100%;
    height: 100%;
  }

  .vc_single_image-img {
    width: 100%;
    object-fit: cover;
  }
}
