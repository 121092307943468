.about {
  & &__wrap {
    margin-top: -10%;
    @media (--phone) {
      margin-top: -20%;
    }

    > .wpb_column > .vc_column-inner > .wpb_wrapper {
      align-items: center;
    }

    .wpb_text_column {
      width: 50%;
      padding: 0 10% 0 2em;
      @media (--phone) {
        width: 100%;
        padding: 1em 1em 0;
      }
    }
  }
}
