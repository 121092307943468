.about-features {
  > .wpb_column {
    padding: 0 var(--a-bd_w);
  }

  .wpb_text_column {
    position: relative;
    width: calc(( 100% - 3em ) / 3);
    padding: 0 2em 4em;
    background-color: var(--gray);
    transition: background-color .3s;
    @media (--phone) {
      width: 100%;
      margin-top: 1em;
    }

    &:hover {
      background-color: var(--red);
    }

    &::before {
      content: '';
      display: block;
      width: var(--adorn_w);
      height: var(--adorn_h);
      margin-bottom: 2em;
      background-color: white;
    }
  }

  &&_blue {
    .wpb_text_column {
      background-color: var(--blue);

      &:hover {
        background-color: var(--red);
      }
    }
  }
}
