.h-projects {
  background: linear-gradient(#f0f0f0, #858587);

  .projects__head.h-projects__head{
    background-color: var(--red);
  }

  .owl-nav,
  .owl-carousel {
    &:hover {
      .owl-next {
        right: -40px;
      }

      .owl-prev {
        left: -40px;
      }
    }
  }

  .owl-carousel .owl-stage {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .owl-nav {
    position: absolute;
    right: 8%;
    bottom: 25%;
    @media (--phone) {
      right: 12%;
      bottom: 15%;
    }

    .owl-next,
    .owl-prev {
      background-color: transparent !important;
    }
  }

  .owl-item {
    .item {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      position: relative;
      height: var(--pro_h);
      @media (--desktop) {
        padding-right: 15%;
      }
      @media (--ipad) {
        padding-right: 15%;
      }
      @media (--ipad_mini) {
        padding-right: 15%;
      }
    }

    .wpb_single_image {
      @mixin projectsImage;
    }
  }
}
