.projects {
  & &__item {
    margin-left: -.6em;
    margin-right: -.6em;

    + .projects__item {
      margin-top: 3em;
      @media (--phone) {
        margin-top: 2em;
      }
    }

    &:hover {
      .projects__head {
        background-color: var(--red);
      }
    }

    &:nth-child(odd) {
      .projects__head {
        left: auto;
        right: 0;
        @media (--phone) {
          left: auto;
          right: 50%;
        }
      }

      > .wpb_column > .vc_column-inner > .wpb_wrapper {
        @media (--desktop) {
          padding-right: 15%;
        }
        @media (--ipad) {
          padding-right: 15%;
        }
        @media (--ipad_mini) {
          padding-right: 15%;
        }
      }
    }

    &:nth-child(even) {
      .projects__head {
        left: 0;
        right: auto;
        @media (--phone) {
          left: auto;
          right: 50%;
        }
      }

      > .wpb_column > .vc_column-inner > .wpb_wrapper {
        @media (--desktop) {
          padding-left: 15%;
        }
        @media (--ipad) {
          padding-left: 15%;
        }
        @media (--ipad_mini) {
          padding-left: 15%;
        }
      }
    }

    > .wpb_column > .vc_column-inner > .wpb_wrapper {
      height: var(--pro_h);
    }

    .wpb_single_image {
      @mixin projectsImage;
    }

    .vc_single_image-wrapper.prettyphoto {
      position: relative;

      &:hover {
        &::before {
          opacity: 1;
        }
      }
      
      &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: url(../images/icon_large.png) 50% 50% no-repeat color(black a(.5));
        opacity: 0;
        transition: opacity .6s;
      }
    }
  }
}
