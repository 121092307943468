.about-policy {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  margin-top: 2em;

  .wpb_text_column {
    padding: 0 5%;
    text-align: justify;
  }
}
