.h-products {
  .woocommerce .products {
    .wpb_text_column {
      width: 50%;
      padding: 2.6em;
      @media (--phone) {
        padding: 1em;
      }
    }
  }
}
