.projects {
  > .vc_tta-tabs-container:first-child {
    display: none !important;
  }

  .vc_tta-panels {
    background-color: transparent !important;
    border: none !important;
  }

  .vc_tta-panel {
    &.vc_active {
      .vc_tta-panel-title > a:after {
        visibility: hidden;
      }
    }

    + .vc_tta-panel {
      margin-top: 2em !important;
      @media (--phone) {
        margin-top: 1em !important;
      }
    }

    .vc_tta-panel-body {
      padding: 3em 0 !important;
      background-color: transparent !important;
      border: none !important;
      @media (--phone) {
        padding: 2em 0 !important;
      }
    }

    .vc_tta-panel-heading {
      display: block !important;
      border: none !important;
      background-color: var(--gray_l) !important;

      .vc_tta-panel-title > a {
        padding: .3em 0 !important;
      }

      .vc_tta-title-text {
        font: 300 1.8rem/1 Oswald, sans-serif;
      }
    }

    .vc_tta-panel-title {
      text-align: center;
      font: 400 2rem/1.2 Oswald, sans-serif;

      .vc_tta-title-text {
        color: var(--black);
      }

      > a {
        position: relative;

        &::before,
        &::after {
          content: '';
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          background-color: var(--black);
        }

        &::before {
          right: 1em;
          width: .4em;
          height: 3px;
        }

        &::after {
          right: calc( .4em /2 - 3px / 2 + 1em );
          height: .4em;
          width: 3px;
        }
      }
    }
  }
}
