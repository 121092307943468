@mixin font Oswald, Oswald-Bold, 700;
@mixin font Oswald, Oswald-Light, 300;
@mixin font Oswald, Oswald-Medium, 500;
@mixin font Oswald, Oswald-Regular, 400;

@font-face {
  font-family: Oswald;
  font-weight: 400;
  font-style: italic;
  src: url('../fonts/Oswald-RegularItalic.eot'); /* IE9+ */
  src: url('../fonts/Oswald-RegularItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/Oswald-RegularItalic.woff') format('woff'), /* chrome、firefox */
  url('../fonts/Oswald-RegularItalic.woff2') format('woff2'), 
  url('../fonts/Oswald-RegularItalic.ttf') format('truetype'), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
  url('../fonts/Oswald-RegularItalic.svg#fontname') format('svg'); /* iOS 4.1- */
}
