.colors {
  &_blue {
    color: var(--blue);
  }
  &_white {
    color: white;
  }
  &_black {
    color: var(--black);
  }
}
