.gallery {
  & &__wrap {
    @mixin maxW;
    width: calc(100% - var(--a-bd_w) * 2);
    border: var(--a-bd_w) solid white;
    @media (--phone) {
      width: calc(100% - var(--a-bd_w));
      border-width: calc( var(--a-bd_w) / 2 );
    }

    > .wpb_column > .vc_column-inner  > .wpb_wrapper {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-items: flex-start;
    }

    .wpb_single_image {
      overflow: hidden;
      position: relative;
      width: 50%;
      border: var(--a-bd_w) solid white;
      @media (--phone) {
        border-width: calc( var(--a-bd_w) / 2 );
      }

      &:hover {
        .wpb_heading,
        .vc_single_image-wrapper::after {
          opacity: 1;
        }

        .vc_single_image-wrapper::before {
          transform: none;
        }

        .vc_single_image-img {
          transform: scale(1.1, 1.1);
        }
      }

      .wpb_heading {
        position: absolute;
        bottom: 2em;
        left: 50%;
        transform: translateX(-50%);
        z-index: var(--zIndex__bottom);
        font: 400 1.5rem/1.2 Oswald, sans-serif !important;
        color: white;
        opacity: 0;
        transition: opacity .5s;
      }

      .wpb_wrapper,
      .vc_single_image-wrapper,
      .vc_single_image-img {
        width: 100%;
      }

      .vc_single_image-wrapper {
        &::before,
        &::after {
          content: '';
          position: absolute;
        }

        &::before {
          top: 0;
          left: 2.5em;
          z-index: var(--zIndex__bottom);
          width: .4em;
          height: 4em;
          background-color: white;
          transform: translateY(-100%);
          transition: transform .6s;
        }

        &:after {
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: url(../images/icon_large.png) 50% 50% no-repeat color(black a(.4));
          opacity: 0;
          transition: opacity .5s;
        }
      }

      .vc_single_image-img {
        transition: transform .6s;
      }
    }
  }
}
