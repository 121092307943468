.contact-information {
  list-style: none;
  display: flex;
  flex-flow: row wrap;
  @media (--phone) {
    display: block;
  }

  a {
    color: var(--black);
  }
}
