.mainnav {
  .menu {
    &-item {
      text-transform: capitalize;
    }
  }

  &#mainnav {
    .menu {
      &-item {
        padding: 0 1.4em;

        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }

        > a {
          font-size: 1rem;
        }

        &.current-menu-item,
        &.current-page-item,
        &.current_page_parent {
          > a {
            color: var(--red);
          }
        }
      }
    }
  }
}
