.btn {
  &&_themes-arrow {
    width: 50px;
    height: 10px;
    background: svg-inline(arrow) 50%/contain no-repeat;
    font-size: 0;
    transition: transform .4s;

    &:hover {
      transform: translateX(20%);
    }

    &_white {
      background-image: svg-inline(arrow_w);
    }
  }
}
