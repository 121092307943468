.about {
  & &__cover {
    width: 48%;
    border: var(--a-bd_w) solid white;
    @media (--phone) {
      width: 100%;
    }

    .wpb_wrapper,
    .vc_single_image-wrapper,
    .vc_single_image-img {
      width: 100%;
    }
  }
}
