.projects {
  & &__title {
    font: 400 2rem/1.4 Oswald, sans-serif;
    color: white;

    em {
      font-style: normal;
      font: 700 .5em/1.4 Arial, sans-serif;
    }
  }
}
