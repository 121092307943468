.woocommerce {
  ul.products {
    --p: 3em;
    @media (--ipad) {
      --p: 2em;
    }
    @media (--ipad_mini) {
      --p: 2em;
    }
    @media (--phone) {
      --p: 1em;
    }

    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: flex-start;
    @mixin maxW;

    .button.product_type_simple {
      display: none;
    }

    li.type-product {
      width: 50% !important;
      margin-right: 0 !important;
      margin-bottom: 0 !important;
      padding: 1em !important;
      background-color: white;
      @media (--phone) {
        width: 50% !important;
        padding: .5em !important;
      }
    }

    .woocommerce-LoopProduct-link {
      display: block;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: var(--p);
        width: var(--adorn_w);
        height: var(--adorn_h);
        background-color: white;
      }

      &:hover {
        .product {
          &__wrap {
            justify-content: center;
            background-color: var(--red);
          }

          &__short,
          &__btn {
            display: block;
            @media (--phone) {
              display: none;
            }
          }
        }
      }
    }

    .attachment-woocommerce_thumbnail {
      margin-bottom: 0 !important;
    }

    .product {
      &__wrap {
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-end;
        align-items: flex-start;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        padding: var(--p);
        background-color: color(black a(.2));
        transition: background-color .3s;
      }

      &__short,
      &__btn {
        display: none;
      }

      &__short {
        text-align: left;
        color: white;
      }

      &__btn {
        margin-top: 50px;
      }
    }

    .woocommerce-loop-product__title {
      padding: 0 !important;
      text-align: left;
      font: 400 2.5rem/1.4 Oswald, sans-serif !important;
      color: white;
      @media (--phone) {
        font-size: 1.2rem !important;
      }
    }
  }

  &.woocommerce-page {
    .woocommerce-result-count,
    .woocommerce-ordering,
    .woocommerce-breadcrumb, 
    .woocommerce-products-header {
      display: none;
    }

    #primary {
      float: none;
      width: 100%;
    }
  }

  &.archive {
    .site-main {
      padding-bottom: 3em;

      > .products {
        margin-top: -7% !important;

        .product:first-child {
          margin-top: 20%;
        }
      }
    }
  }

  &.single-product {
    .price,
    .product_meta {
      display: none;
    }

    .site-main {
      padding: 3em 0;
      @media (--phone) {
        padding: 3em 15px;
      }
    }

    .woocommerce-product-details__short-description {
      font: 400 1rem/1.8 Arial, sans-serif;
    }

    .woocommerce-product-gallery {
      margin-bottom: 0;
      margin-top: -10%;
      @media (--phone) {
        margin-top: -20%;
      }

      a[href] {
        display: block;
        position: relative;
        width: 100%;
        padding-top: 100%;
        border: .6em solid white;

        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .flex-control-thumbs {
        border-left: .3em solid white;
        border-right: .3em solid white;

        > li {
          width: calc(100% / 6);
          border: .3em solid white;
        }
      }
    }

    .product {
      &_title {
        font-size: 3rem !important;
        font-weight: 400 !important;
      }

      &__content {
        clear: both;
      }

      &__components {
        .wpb_text_column {
          @media (--phone) {
            margin-top: 2em;
            text-align: center;
          }
        }
      }

      &__slogan {
        padding: 12% 0;
        @media (--phone) {
          padding: 50% 0;
        }

        &::after {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background-color: color(black a(.5));
        }
      }
    }
  }
}
