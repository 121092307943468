.contact {
  & &__informations {
    text-align: center;
    @media (--phone) {
      padding: 0 30px;
    }

    > .wpb_column {
      display: inline-block;
      float: none;
      width: auto;
      @media (--phone) {
        display: block;
        width: 100%;
      }

      &:first-child {
        padding-right: 3em;
        @media (--phone) {
          padding-right: 0;
          padding-bottom: 2em;
        }
      }
      &:last-child {
        padding-left: 3em;
        border-left: 1px solid var(--gray);
        @media (--phone) {
          padding-left: 0;
          padding-top: 2em;
          border-left: none;
          border-top: 1px solid var(--gray);
        }
      }
    }

    .wpb_text_column {
      text-align: left;
      @media (--phone) {
        text-align: center;
      }
    }
  }
}
