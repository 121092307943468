.projects {
  & &__head {
    --p: 2em;
    @media (--phone) {
      --p: 1em;
    }

    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    padding: var(--p);
    background-color: var(--gray);
    @media (--phone) {
      top: 0;
      right: 50%;
      transform: translateX(50%);
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: var(--p);
      width: var(--adorn_w);
      height: calc(var(--adorn_h) / 2);
      background-color: white;
    }
  }
}
