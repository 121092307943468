.about-locations {
  > .wpb_column > .vc_column-inner > .wpb_wrapper {
    justify-content: center;
  }

  .wpb_single_image {
    width: calc( 100% / 4 );
    margin-top: 2em;
    text-align: center;
    @media (--phone) {
      width: 50%;
    }

    .vc_single_image-img {
      width: auto;
      max-height: 3.5em;
    }

    .wpb_heading {
      padding: 0 2em;
      @media (--desktop) {
        padding: 0 4em;
      }
    }
  }
}
