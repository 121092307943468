@define-mixin formInput {
  height: 2.4em;
  padding-left: 1em;
  margin-top: .5em;
  border-color: white;
  background-color: transparent;
  font: 400 1rem/1.4 Arial, sans-serif;
  color: white;

  &::placeholder {
    text-transform: capitalize;
    color: white;
    opacity: 1;
  }

  &::-ms-placeholder {
    text-transform: capitalize;
    color: white;
  }
  &:-ms-placeholder {
    text-transform: capitalize;
    color: white;
  }
}
.wpcf7 {
  input {
    &[type="text"],
    &[type="email"],
    &[type="tel"] {
      @mixin formInput;
    }

    &[type="submit"] {
      height: 2.4em;
      margin-top: .5em;
      padding: 0;
      background-color: var(--yellow);
      border-color: var(--yellow);
      border-radius: 0;
      font: 700 1rem/1.2 Arial, sans-serif;
      color: var(--black);

      &:hover {
        background-color: transparent;
        color: white;
      }
    }

    &[name="your-name"],
    &[name="phone"],
    &[name="your-email"],
    &[type="submit"] {
      width: calc(( 100% - 1em ) / 3);
      @media (--phone) {
        width: 100%;
      }
    }

    &[name="your-message"] {
      width: calc(100% - ( 100% - 1em ) / 3 - .5em);
      @media (--phone) {
        width: 100%;
      }
    }

    &[name="phone"],
    &[type="submit"],
    &[name="your-email"] {
      margin-left: .5em;
      @media (--phone) {
        margin-left: 0;
      }
    }
  }
}
