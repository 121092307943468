.footer-widgets {
  padding: 3em 0;
  @media (--phone) {
    text-align: center;
  }

  .container {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: flex-start;
  }

  .widget:last-child {
    margin-bottom: 0;
  }

  address {
    margin-bottom: 0;
  }

  .sidebar-column {
    padding: 0 15px;
    @media (--phone) {
      + .sidebar-column {
        margin-top: 2em;
      }
    }

    &:first-child {
      width: 24%;  
      text-align: center;
      @media (--phone) {
        width: 100%;
      }
    }

    &:nth-child(2) {
      width: 34%;
      @media (--phone) {
        width: 100%;
      }
    }

    &:last-child {
      width: 42%;
      border-left: 1px solid var(--gray);
      padding-left: 4%;
      @media (--phone) {
        width: 100%;
        padding-left: 0;
        border-left: none;
      }
    }
  }

  .widget-title {
    margin-top: 0;
    padding-bottom: .5em;
    font-weight: 400;
    font-style: italic;
    font-size: 1rem;
  }

  #media_image-2 {
    margin-bottom: 0;
  }

  #text-2 {
    padding-top: 0;
  }

  .site-company {
    margin-top: 1em;
    font: italic 400 1rem/1.4 Oswald, sans-serif;

    em {
      display: block;
      font-style: normal;
      font-size: .7em;
      font-weight: 300;
    }
  }

  ul {
    li {
      padding: 0 !important;
    }
  }
}
